import React from "react"
import { StaticQuery, graphql } from "gatsby"
import SocialButtons from "./socialButtons"

const RightBar = props => {
  const hideNotRoot = !props.is_root ? "is-hidden-touch" : ""
  return (
    <StaticQuery
      query={rightBar}
      render={data => {
        const { author } = data.site.siteMetadata
        return (
          <div>
            <div>
              <figure className="image is-128x128 is-rounded m_bottom_20">
                <img
                  src={data.avatar.childImageSharp.fixed.src}
                  style={{
                    borderRadius: `50%`,
                  }}
                  alt={author}
                />
              </figure>
              <p className={`m_top_20-desktop m_bottom_20 ${hideNotRoot}`}>
                {/* Hey!! I'm <strong>thanos</strong>, front-end engineer. I write
                to share my knowledge and help other developers! */}
              </p>
            </div>
            <div className={`m_bottom_20 ${hideNotRoot} is-hidden-touch`}>
              <h3>Where you can find me</h3>
              <SocialButtons />
            </div>
          </div>
        )
      }}
    />
  )
}

const rightBar = graphql`
  query rightBar {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      childImageSharp {
        fixed {
          src
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter
        }
      }
    }
  }
`
export default RightBar
