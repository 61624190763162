import React from "react"
import { StaticQuery, graphql } from "gatsby"

function SocialButtons() {
  return (
    <StaticQuery
      query={_social}
      render={data => {
        const { social } = data.site.siteMetadata
        let _email,
          _twitter,
          _github,
          _medium,
          _linkedIn,
          _codepen = null
        if (social.email) {
          _email = (
            <a
              className="social_button"
              rel="noopener noreferrer"
              target="_blank"
              href={`malto:${social.email}`}
            >
              <i className="fas fa-envelope" />
            </a>
          )
        }
        if (social.twitter) {
          _twitter = (
            <a
              className="social_button"
              rel="noopener noreferrer"
              target="_blank"
              href={social.twitter}
            >
              <i className="fab fa-twitter" />
            </a>
          )
        }
        if (social.github) {
          _github = (
            <a
              className="social_button"
              rel="noopener noreferrer"
              target="_blank"
              href={social.github}
            >
              <i className="fab fa-github" />
            </a>
          )
        }
        if (social.medium) {
          _medium = (
            <a
              className="social_button"
              rel="noopener noreferrer"
              target="_blank"
              href={social.medium}
            >
              <i className="fab fa-medium" />
            </a>
          )
        }
        if (social.linkedIn) {
          _linkedIn = (
            <a
              className="social_button"
              rel="noopener noreferrer"
              target="_blank"
              href={social.linkedIn}
            >
              <i className="fab fa-linkedin" />
            </a>
          )
        }
        if (social.codepen) {
          _codepen = (
            <a
              className="social_button"
              rel="noopener noreferrer"
              target="_blank"
              href={social.codepen}
            >
              <i className="fab fa-codepen" />
            </a>
          )
        }
        return (
          <div>
            {_email}
            {_github}
            {_linkedIn}
            {_codepen}
          </div>
        )
      }}
    />
  )
}

const _social = graphql`
  query social {
    site {
      siteMetadata {
        social {
          email
          github
          linkedIn
          codepen
        }
      }
    }
  }
`

export default SocialButtons
