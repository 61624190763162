import React from "react"
import { Link } from "gatsby"
import RightBar from "./right_bar"

class Layout extends React.Component {
  renderRightBar = () => {
    return (
      <RightBar
        is_root={
          this.props.location.pathname === `${__PATH_PREFIX__}/` ? true : false
        }
      />
    )
  }

  render() {
    return (
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-three-quarter-desktop is-offset-3">
              <div className="header">
                <Link to="/">
                  <div className="title is-2 main_title m_bottom_30-desktop">
                    @th.stamod
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column is-one-quarter-desktop">
              {this.renderRightBar()}
            </div>
            <div className="column is-three-quarter-desktop">
              <main>{this.props.children}</main>
            </div>
          </div>
        </div>
        <footer className="footer_stick">
          © {new Date().getFullYear()} Thanos Stamodimos, Built with
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </footer>
      </section>
    )
  }
}

export default Layout
